import {makeStyles} from '@material-ui/core/styles';

export default makeStyles(() => ({
    container: {
        width: '100%',
        display: 'flex',
    },

    modalWrapper: {
        width: '500px',
        minWidth: '500px',
        height: '500px',
    },

    textContainer: {
        display: 'flex',
        fontSize: 23,
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '40px',
        alignSelf: 'start',
        paddingLeft: '40px',
    },

    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '48px',
    },
}));
