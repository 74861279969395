import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(() => ({
  container: {
    marginTop: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    minWidth: 650,
    paddingBottom: 50,
  },

  tableLoadingContainer: {
    minWidth: 300,
    margin: 'auto',
    marginTop: '60px',
    height: '57em',
    display: 'flex',
    justifyContent: 'center',
    width: '80em',
    '@media (max-width: 1300px)': {
      width: '60em',
    },
    '@media (max-width: 1000px)': {
      width: '40em',
    },
    '@media (max-width: 650px)': {
      width: '30em',
    },
  },

  tableContainer: {
    minWidth: 300,
    margin: 'auto',
    marginTop: '40px',
    display: 'flex',
    justifyContent: 'center',
    width: '80em',
    '@media (max-width: 1300px)': {
      width: '60em',
    },
    '@media (max-width: 1000px)': {
      width: '50em',
    },
    '@media (max-width: 820px)': {
      width: '30em',
    },
  },
}))
