import { Button } from 'front-components/dist/cjs'
import { TopBar } from 'front-components/dist/cjs'
import { InputField } from 'front-components/dist/cjs'
import { ContentTable } from 'front-components/dist/cjs'
import useStyle from './Home.styles'
import { Form, Formik } from 'formik'
import { Alert } from 'front-components/dist/cjs'
import { useEffect, useMemo, useState } from 'react'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import {
  getThanks,
  addThanks,
  userLogout,
  getReactions,
  addThanksCustomRecipient as addThanksCustomRecipientMutation,
  addReaction as addReactionMutation,
  deleteReaction as deleteReactionMutation,
  getLoggedUser,
  updateReaction as updateReactionMutation,
} from './HomeGraphQL'
import { useNavigate } from 'react-router'
import logo from '../../Images/fingoweb_logo.png'
import useApplicationCookies from '../../utils/useApplicationCookies'
import config from '../../config'
import classNames from 'classnames'
import EmployeesInput from '../../components/EmployeesInput/EmployeesInput'
import { REACTIONS_MAP, THANKS_TABLE_STYLES } from './Home.const'
import { prepareRowsData } from './Home.util'
import Rank from '../../components/Rank/Rank'
import { addThankValidation } from './Home.validation'
import { paginationLabelDisplayedRows } from '../../utils/paginationLabelDisplayedRows'

function Home({ client }) {
  const [blockedThankIdActions, setBlockedThankIdActions] = useState([])
  const [showErrorPopUp, setShowErrorPopUp] = useState(false)
  const [showSuccessPopUp, setShowSuccessPopUp] = useState(false)
  const [paginationPage, setPaginationPage] = useState(0)
  const [rows, setRows] = useState([])

  const { removeCookies } = useApplicationCookies('FingowebAccessToken')
  const navigate = useNavigate()
  const classes = useStyle()

  const userData = useQuery(getLoggedUser)
  const { data: reactionsData } = useQuery(getReactions, {})
  const [getThank, { data, loading, refetch: refetchThanks, called }] =
    useLazyQuery(getThanks)
  const [logout] = useMutation(userLogout)
  const [addThank, { loading: isLoadingAddThank }] = useMutation(addThanks)
  const [addThankCustomRecipient, { loading: isLoadingAddThankCustom }] =
    useMutation(addThanksCustomRecipientMutation)
  const [addReaction] = useMutation(addReactionMutation)
  const [updateReaction] = useMutation(updateReactionMutation)
  const [deleteReaction] = useMutation(deleteReactionMutation)

  const rowsPerPage = 7
  const userId = userData.data?.me?.id
  const myUserName = userData.data?.me?.name
  const reactionData = reactionsData?.emotions.data

  const mappedRows = useMemo(() => {
    return prepareRowsData(rows, myUserName)
  }, [myUserName, rows])

  const refetchRank = async (cb) => {
    await client.refetchQueries({
      include: ['rank'],
    })
  }

  const onSubmit = (values, resetForm) => {
    const isCustomRecipient = typeof values.for_who === 'string'
    const forWho = isCustomRecipient
      ? values.for_who
      : values.for_who.map((obj) => ({ user_id: +obj.id }))

    let request

    if (isCustomRecipient) {
      request = addThankCustomRecipient({
        variables: {
          recipient: forWho,
          content: values.content,
          senderId: userId,
        },
      })
    } else {
      request = addThank({
        variables: {
          recipientIds: forWho,
          content: values.content,
          senderId: userId,
        },
      })
    }

    request
      .then((r) => {
        if (showErrorPopUp) {
          setShowErrorPopUp(false)
        }
        setShowSuccessPopUp(true)
        setPaginationPage(0)

        setRows((prevRows) => [
          {
            id: r.data.thankCreate.id,
            for_who: r.data.thankCreate.for_who,
            from_who: r.data.thankCreate.from_who,
            content: r.data.thankCreate.content,
            created_at: r.data.thankCreate.created_at,
          },
          ...prevRows,
        ])

        resetForm()
        refetchRank()
      })
      .catch((error) => {
        console.log('Server error has occurred!', error)
      })
  }

  const handleLogout = () => {
    logout().then(() => {
      removeCookies('FingowebAccessToken', {
        path: '/',
        domain: config.cookieDomain ?? '',
      })
      window.location.reload()
    })
  }

  useEffect(() => {
    getThank({
      variables: {
        first: rowsPerPage,
        page: paginationPage + 1,
        orderBy: [
          {
            column: 'created_at',
            order: 'DESC',
          },
        ],
      },
    })
      .then((r) => {
        if (r.data !== undefined) {
          setRows(r.data.thanks.data)
          setBlockedThankIdActions([])
        } else {
          navigate('/signIn', { replace: true })
        }
      })
      .catch((error) => console.log(error))
  }, [paginationPage, getThank, navigate, data, called])

  return (
    <>
      <TopBar image={logo} onLogout={handleLogout} />

      <div className={classes.container}>
        <Formik
          initialValues={{ for_who: [], content: '' }}
          onSubmit={(values, { resetForm, ...test }) => {
            if (isLoadingAddThank || isLoadingAddThankCustom) return
            onSubmit(values, resetForm)
          }}
          validationSchema={addThankValidation}
          enableReinitialize
        >
          {({
            handleChange,
            errors,
            values,
            touched,
            handleBlur,
            isSubmitting,
          }) => (
            <Form>
              <div className={classes.inputContainer}>
                <div className={classes.reasonInputContainer}>
                  <EmployeesInput />

                  <InputField
                    value={values.content}
                    label={'Za'}
                    name='content'
                    rows={1}
                    width={'100%'}
                    variant={'standard'}
                    errorMessage={touched.content ? errors.content : null}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </div>
              <div
                className={classNames(classes.submitButtonContainer, {
                  [classes.submitButtonContainerShow]:
                    !showErrorPopUp || !Object.keys(errors).length,
                })}
              >
                <Button
                  label={'Podziękuj'}
                  variant={'contained'}
                  size={'medium'}
                  onClick={() =>
                    setShowErrorPopUp(!!Object.keys(errors).length)
                  }
                />
              </div>
              <div className={classes.alertContainer}>
                {!!Object.keys(errors).length && (
                  <div
                    className={classNames(
                      classes.alertError,
                      { [classes.alertErrorShow]: showErrorPopUp },
                      {
                        [classes.alertErrorWithOtherAlert]:
                          showSuccessPopUp && showErrorPopUp,
                      },
                    )}
                  >
                    <Alert
                      text={
                        Object.entries(errors)[0][1] ===
                        'To pole jest wymagane!'
                          ? 'Uzupełnij wymagane pola!'
                          : Object.entries(errors)[0][1]
                      }
                      type={'error'}
                      show={true}
                      onClose={() => setShowErrorPopUp(false)}
                      marginBottom={'20px'}
                    />
                  </div>
                )}
                <div
                  className={classNames(classes.alertSuccess, {
                    [classes.alertSuccessWithErrors]:
                      !!Object.keys(errors).length,
                  })}
                >
                  <Alert
                    text={'Dodano podziękowanie!'}
                    type={'success'}
                    show={showSuccessPopUp}
                    onClose={() => setShowSuccessPopUp(false)}
                  />
                </div>
              </div>
              <div
                className={
                  loading
                    ? classes.tableLoadingContainer
                    : classes.tableContainer
                }
              >
                <ContentTable
                  columns={[
                    {
                      id: 'from_who',
                      label: 'Od',
                    },
                    {
                      id: 'for_who',
                      label: 'Dla',
                    },
                    {
                      id: 'content',
                      label: 'Za',
                    },
                    {
                      id: 'created_at',
                      label: 'Data',
                    },
                  ]}
                  rows={mappedRows}
                  sx={THANKS_TABLE_STYLES}
                  reactionsMap={REACTIONS_MAP}
                  onReactionClick={async (
                    reaction,
                    thankId,
                    givenReactions,
                  ) => {
                    const updateRowData = (thankData) => {
                      setRows((prevRows) => {
                        return prevRows.map((row) => {
                          if (row.id === thankData.id) {
                            return {
                              ...row,
                              reactions: thankData.reactions,
                            }
                          }

                          return row
                        })
                      })
                    }

                    const { id: emotionId } = reactionData.find(
                      ({ id, system_name }) => system_name === reaction,
                    )

                    // check if user already reacted with this emotion
                    const { reactionId } =
                      givenReactions.find(({ reactionId, type }) => {
                        if (type === reaction) {
                          return reactionId
                        }

                        return null
                      }) || {}

                    if (reactionId) {
                      const deletedReaction = await deleteReaction({
                        variables: { reactionId },
                      })

                      updateRowData(deletedReaction.data.reactionDelete.thank)
                      refetchThanks()

                      return
                    }

                    if (givenReactions.length > 0) {
                      const updatedReactions = await updateReaction({
                        variables: {
                          thankId,
                          userId,
                          emotionId,
                          id: givenReactions[0].reactionId,
                        },
                      })
                      updateRowData(updatedReactions.data.reactionCreate.thank)
                      refetchThanks()
                    } else {
                      if (blockedThankIdActions.includes(thankId)) return

                      setBlockedThankIdActions(thankId)

                      const addedReaction = await addReaction({
                        variables: { thankId, userId, emotionId },
                      })

                      updateRowData(addedReaction.data.reactionCreate.thank)
                      refetchThanks()
                    }
                  }}
                  numberOfElements={data ? data.thanks.paginatorInfo.total : 0}
                  paginationPage={paginationPage}
                  setPaginationPage={setPaginationPage}
                  rowsPerPage={rowsPerPage}
                  headerBackgroundColor={'#0B0826'}
                  loading={loading}
                  paginationLabelDisplayedRows={paginationLabelDisplayedRows}
                />
              </div>
            </Form>
          )}
        </Formik>

        <Rank />
      </div>
    </>
  )
}

export default Home
