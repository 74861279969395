const prepareRowsData = (rows, myUserName) => {
  return rows.map((row) => {
    const reactions = [
      {
        type: 'laugh',
        people: [],
      },
      {
        type: 'like',
        people: [],
      },
      {
        type: 'check',
        people: [],
      },
      {
        type: 'love',
        people: [],
      },
      {
        type: 'wow',
        people: [],
      },
      {
        type: 'smile',
        people: [],
      },
      {
        type: 'joy',
        people: [],
      },
      {
        type: 'sports_medal',
        people: [],
      },
      {
        type: 'muscle',
        people: [],
      },
      {
        type: 'tada',
        people: [],
      },
      {
        type: 'scream',
        people: [],
      },
      {
        type: 'raised_hands',
        people: [],
      },
      {
        type: 'sunglasses',
        people: [],
      },
      {
        type: 'grin',
        people: [],
      }
    ]

    row.reactions?.forEach(({ emotion, user, id: reactionId }) => {
      const foundType = reactions.find(
        (reaction) => reaction.type === emotion.system_name,
      )

      foundType.people.push({ name: user.name, reactionId })
    })

    return {
      ...row,

      reactions: reactions
        .filter(({ people }) => people.length !== 0)
        .map(({ people, type }) => ({
          type,
          people: people.map((person) => person.name),
        })),
      givenReactions: reactions
        .map(({ type, people }) => {
          const foundType = people.find(
            ({ name, reactionId }) => name === myUserName,
          )

          return foundType ? { type, reactionId: foundType.reactionId } : null
        })
        .filter(Boolean),
    }
  })
}

export { prepareRowsData }
