import { useLocation, Navigate, Outlet } from 'react-router-dom'
import { getLoggedUser } from '../pages/Home/HomeGraphQL'
import { useLazyQuery } from '@apollo/client'
import { useEffect, useRef, useState } from 'react'

const ProtectedRoute = () => {
  const [loggedUser] = useLazyQuery(getLoggedUser, {
    fetchPolicy: 'network-only',
  })

  const [isLoading, setIsLoading] = useState(true)
  const location = useLocation()
  const authorized = useRef(false)

  useEffect(() => {
    loggedUser()
      .then((r) => {
        authorized.current = r.data !== undefined
        setIsLoading(false)
      })
      .catch((e) => console.warn(e))
  }, [authorized, loggedUser])

  return (
    <>
      {!isLoading && authorized.current && <Outlet />}
      {!isLoading && !authorized.current && (
        <Navigate to='/signIn' replace state={{ from: location }} />
      )}
    </>
  )
}

export default ProtectedRoute
