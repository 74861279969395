import { makeStyles } from '@material-ui/styles'
import { styled } from '@mui/material'
import { TextField } from '@mui/material'

export default makeStyles((theme) => ({
  input: {
    '& .MuiAutocomplete-tag': {
      backgroundColor: '#00BAE7',
      color: 'white',
    },

    '& .MuiChip-deleteIcon': {
      color: 'white !important',
      backgroundColor: '#00BAE7',
    },
  },

  switch: {
    width: 34,
    height: 18,
  },
}))

export const InputContainer = styled('div')(({ theme }) => ({
  fontFamily: 'urbane,sans-serif',
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  justifyContent: 'space-between',
}))

export const Input = styled(TextField)(({ theme, isError }) => {
  return {
    '& .MuiInput-root:hover:before': {
      borderBottom: `1px solid ${theme.superDarkBlue} !important`,
    },

    '& .MuiInput-root:focus:before': {
      borderBottom: `1px solid ${theme.superDarkBlue} !important`,
    },

    '& .MuiInput-underline:after': {
      borderBottomColor: isError ? theme.red : theme.blue,
      borderWidth: '1px',
    },

    '& .MuiInput-underline:before': {
      borderBottomColor: isError ? theme.red : theme.superDarkBlue,
      borderWidth: '1px',
      opacity: isError ? 1 : 0.4,
    },
  }
})

export const BottomInputData = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: 8,
  justifyContent: 'space-between',

  '& p': {
    color: '#7D7E81',
    margin: 0,
    fontSize: 14,
    fontWeight: 400,
  },
}))

export const SwitchContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  marginLeft: 'auto',
  gap: 8,

  '& p': {
    color: '#0B0826',
  },
}))

export const ErrorContainer = styled('div')(({ theme }) => ({
  fontSize: 13,
  color: theme.red,
}))
