import useStyle from "./SignIn.styles";
import {LoginModal} from "front-components/dist/cjs";
import {GoogleLoginButton} from "../../components/GoogleLoginButton/GoogleLoginButton";

function SignIn() {

    const classes = useStyle();

    return (
        <div className={classes.container}>
            <div className={classes.modalWrapper}>
                <LoginModal open contentStyles={{display: 'flex', margin: 'auto', marginTop: '250px', position: 'relative',}}>
                    <div className={classes.textContainer}>
                        {'Logowanie'}
                    </div>
                    <div className={classes.buttonContainer}>
                        <GoogleLoginButton />
                    </div>
                </LoginModal>
            </div>
        </div>
    );
}

export default SignIn;
