import gql from 'graphql-tag'

export const getEmployees = gql`
  query users {
    users(first: 99999, page: 1, orderBy: [{ column: "email", order: ASC }]) {
      data {
        id
        email
        firstname
        lastname
      }
      paginatorInfo {
        count
        currentPage
        hasMorePages
        perPage
        total
      }
    }
  }
`
