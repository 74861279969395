import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { CookiesProvider } from 'react-cookie'
import { ThemeProvider } from '@mui/material/styles'
import { LibraryThemeProvider, appTheme } from 'front-components/dist/cjs'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
    <LibraryThemeProvider theme={appTheme}>
      <ThemeProvider theme={appTheme}>
        <CookiesProvider>
          <App />
        </CookiesProvider>
      </ThemeProvider>
    </LibraryThemeProvider>
  </React.StrictMode>,
)
