import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(() => ({
  container: {
    fontFamily: 'urbane,sans-serif',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    minWidth: 650,
    paddingBottom: 50,
  },

  inputContainer: {
    marginTop: '150px',
    display: 'flex',
    flexDirection: 'column',
  },

  nameInputContainer: {
    display: 'flex',
    justifyContent: 'center',
    '@media (max-width: 850px)': {
      flexDirection: 'column',
      paddingLeft: '20%',
    },

    '@media (max-width: 650px)': {
      flexDirection: 'column',
      paddingLeft: '20%',
    },
  },

  recipientInput: {
    marginLeft: 35,
    '@media (max-width: 850px)': {
      marginLeft: 0,
      marginTop: 40,
    },
  },

  reasonInputContainer: {
    marginTop: 60,
    display: 'flex',
    flexDirection: 'column',
    gap: 40,
    alignSelf: 'center',
    width: 795,
    '@media (max-width: 850px)': {
      marginTop: 40,
      width: 500,
    },
    '@media (max-width: 650px)': {
      marginTop: 40,
      width: 380,
    },
  },

  submitButtonContainer: {
    marginTop: '60px',
    marginBottom: '80px',
    display: 'flex',
    justifyContent: 'center',
    opacity: 0,
    transform: 'translateY(-50px)',
    transition: 'all 250ms ease-in-out',
  },

  submitButtonContainerShow: {
    opacity: 1,
    transition: 'all 250ms ease-in-out',
    transform: 'translateY(0px)',
  },

  tableContainer: {
    minWidth: 300,
    margin: 'auto',
    marginTop: '40px',
    display: 'flex',
    justifyContent: 'center',
    width: '80em',
    '@media (max-width: 1300px)': {
      width: '60em',
    },
    '@media (max-width: 1000px)': {
      width: '50em',
    },
    '@media (max-width: 820px)': {
      width: '30em',
    },
  },

  tableLoadingContainer: {
    minWidth: 300,
    margin: 'auto',
    marginTop: '60px',
    height: '57em',
    display: 'flex',
    justifyContent: 'center',
    width: '80em',
    '@media (max-width: 1300px)': {
      width: '60em',
    },
    '@media (max-width: 1000px)': {
      width: '40em',
    },
    '@media (max-width: 650px)': {
      width: '30em',
    },
  },

  alertContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '20px',
    width: '50em',
    margin: 'auto',
    '@media (max-width: 1300px)': {
      width: '39.5em',
    },
  },

  alertSuccess: {
    margin: 'auto',
    transform: 'translateY(-70px)',
    marginBottom: '-70px',
    marginTop: '25px',
  },

  alertSuccessWithErrors: {
    marginTop: '-35px',
  },

  alertError: {
    margin: 'auto',
    opacity: 0,
    transform: 'translateY(-100px)',
    transition: 'all 250ms ease-in-out',
    pointerEvents: 'none',
    marginBottom: '-30px',
  },

  alertErrorShow: {
    opacity: 1,
    transition: 'all 250ms ease-in-out',
    transform: 'translateY(-240px)',
    pointerEvents: 'auto',
    marginBottom: '-80px',
  },

  alertErrorWithOtherAlert: {
    opacity: 1,
    transition: 'all 250ms ease-in-out',
    transform: 'translateY(-170px)',
    pointerEvents: 'auto',
    marginBottom: '-80px',
  },
}))
