import * as Yup from 'yup'

const addThankValidation = Yup.object().shape({
  for_who: Yup.lazy((value) => {
    if (typeof value === 'string') {
      return Yup.string().required('To pole jest wymagane!')
    }

    return Yup.array()
      .min(1, 'Musisz wybrać minimum jedną osobę!')
      .required('To pole jest wymagane!')
  }),
  content: Yup.string()
    .max(255, 'Zbyt wiele znaków!')
    .required('To pole jest wymagane!'),
})

export { addThankValidation }
