import gql from 'graphql-tag'

export const getRankingData = gql`
  query rank($first: Int!, $page: Int!) {
    rank: usersWithTrashed(
      first: $first
      page: $page
      orderBy: [{ column: "thanks_count", order: DESC }]
      thanksFilter: true
    ) {
      data {
        id
        email
        name
        firstname
        lastname
        thanks_count
        __typename
        last_thank_date
      }
      paginatorInfo {
        count
        currentPage
        hasMorePages
        perPage
        total
        __typename
      }
      __typename
    }
  }
`
