import gql from 'graphql-tag';

export const getLoginToken = gql`
    mutation loginWithGoogle($id_token: String!){
        googleAuthLogin(id_token: $id_token){
            user{
                email
            }
            message
            token
            expires_in
        }
    }
`
