import {useCallback, useEffect, useRef} from "react"
import {useNavigate} from "react-router";
import config from "../../config";
import {useMutation} from "@apollo/client";
import {getLoginToken} from "./GoogleLoginGraphQL";
import useApplicationCookies from "../../utils/useApplicationCookies";

export const GoogleLoginButton = () => {
    const [getToken] = useMutation(getLoginToken);
    const {setCookies} = useApplicationCookies("FingowebAccessToken");
    const navigate = useNavigate();
    const divRef = useRef(null);

    const handleGoogleSignIn = useCallback(
        async (res) => {
            getToken({variables: {id_token: res.credential}}).then(response => {
                const {token, expiresIn} = response.data.googleAuthLogin;
                const expires = new Date(Date.now() + expiresIn * 1000);

                setCookies("FingowebAccessToken", token, {
                    path: "/",
                    domain: config.cookieDomain,
                    expires,
                });

                navigate("/", {replace: true});
            }).catch(error => {
                console.log("Failed to login!", error)
            })
        }, [getToken, navigate, setCookies]
    );

    useEffect(() => {
        window.google.accounts.id.initialize({
            client_id: config.clientId,
            callback: handleGoogleSignIn,
        })

        window.google.accounts.id.renderButton(divRef.current, {
            theme: 'filled_blue',
            shape: 'pill',
            width: 400,
            size: 'large',
        });
    }, [handleGoogleSignIn])

    return (
        <div ref={divRef} />
    )
}
