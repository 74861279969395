const RANK_TABLE_STYLES = {
  table: {
    minWidth: 900,
    overflowX: 'auto',
  },
  '.MuiTablePagination-root': {
    minWidth: 900,
  },

  'tr td:first-child': {
    width: 'auto',
  },
  'tr td:nth-child(2)': {
    width: 'auto',
    wordBreak: 'break-word',
  },
  'tr td:nth-child(3)': {
    width: 'auto',
  },
  'tr td:nth-child(4)': {
    width: 'auto',
  },
  'tr td:last-child': {
    minWidth: 'auto',
  },
}

export { RANK_TABLE_STYLES }
