import { ContentTable } from 'front-components/dist/cjs'
import { useState, useMemo } from 'react'
import { useQuery } from '@apollo/client'
import useStyle from './Rank.styles'
import { RANK_TABLE_STYLES } from './Rank.const'

import { getRankingData } from './RankGraphQL'
import { paginationLabelDisplayedRows } from '../../utils/paginationLabelDisplayedRows'

const Rank = () => {
  const [paginationPage, setPaginationPage] = useState(0)

  const classes = useStyle()
  const rowsPerPage = 5

  const { data: rankData, loading } = useQuery(getRankingData, {
    variables: {
      page: paginationPage + 1,
      first: rowsPerPage,
    },
  })

  const mappedRows = useMemo(() => {
    if (!rankData) return []

    const rank = rankData.rank.data

    return rank.map((item, index) => {
      return {
        position:
          paginationPage === 0
            ? index + 1
            : index + 1 + rowsPerPage * paginationPage,
        firstname: item.firstname,
        lastname: item.lastname,
        thanksCount: item.thanks_count,
        lastThank: item.last_thank_date,
      }
    })
  }, [rankData, paginationPage])

  return (
    <div className={classes.container}>
      <h1>Ranking wystawionych podziękowań</h1>

      <div
        className={
          loading ? classes.tableLoadingContainer : classes.tableContainer
        }
      >
        <ContentTable
          sx={RANK_TABLE_STYLES}
          columns={[
            {
              id: 'position',
              label: 'Miejsce',
            },
            {
              id: 'firstname',
              label: 'Imię',
            },
            {
              id: 'lastname',
              label: 'Nazwisko',
            },
            {
              id: 'thanksCount',
              label: 'Ilość',
            },
            {
              id: 'lastThank',
              label: 'Data ostatniego',
            },
          ]}
          rows={mappedRows}
          numberOfElements={rankData ? rankData.rank.paginatorInfo.total : 0}
          paginationPage={paginationPage}
          setPaginationPage={setPaginationPage}
          rowsPerPage={rowsPerPage}
          headerBackgroundColor={'#0B0826'}
          loading={loading}
          paginationLabelDisplayedRows={paginationLabelDisplayedRows}
        />
      </div>
    </div>
  )
}

export default Rank
