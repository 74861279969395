const THANKS_TABLE_STYLES = {
  table: {
    minWidth: 900,
    overflowX: 'auto',
  },
  '.MuiTablePagination-root': {
    minWidth: 900,
  },

  'tr td:first-child': {
    minWidth: 150,
  },
  'tr td:nth-child(2)': {
    width: '30%',
    wordBreak: 'break-word',
  },
  'tr td:nth-child(3)': {
    width: '50%',
  },
  'tr td:last-child': {
    minWidth: 155,
  },
}

const REACTIONS_MAP = {
  laugh: '😄',
  like: '👍',
  love: '❤️',
  check: '✅',
  wow: '😮',
  smile: '🙂',
  joy: '😂',
  sports_medal: '🏅',
  muscle: '💪',
  tada: '🎉',
  scream: '😱',
  raised_hands: '🙌',
  sunglasses: '😎',
  grin: '😁',
}

export { THANKS_TABLE_STYLES, REACTIONS_MAP }
