import Home from './pages/Home'
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client'
import config from './config.js'
import { setContext } from '@apollo/client/link/context'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import ProtectedRoute from './utils/ProtectedRoute'
import SignIn from './pages/SignIn'
import useApplicationCookies from './utils/useApplicationCookies'

function App() {
  const { cookies } = useApplicationCookies('FingowebAccessToken')

  const httpLink = createHttpLink({
    uri: config.apiUrl,
  })

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: 'Bearer ' + cookies.FingowebAccessToken,
      },
    }
  })

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  })

  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <Routes>
          <Route path='/signIn' element={<SignIn />} />
          <Route element={<ProtectedRoute />}>
            <Route path='/' element={<Home client={client} />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ApolloProvider>
  )
}

export default App
