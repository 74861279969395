import gql from 'graphql-tag'

export const getThanks = gql`
  query getThanks($first: Int!, $page: Int!, $orderBy: [OrderByClause!]) {
    thanks(first: $first, page: $page, orderBy: $orderBy) {
      data {
        id
        from_who
        for_who
        content
        created_at
        reactions {
          emotion {
            system_name
          }
          user {
            name
          }
          id
        }
      }
      paginatorInfo {
        count
        currentPage
        hasMorePages
        perPage
        total
      }
    }
  }
`

export const addThanks = gql`
  mutation thank($senderId: ID!, $recipientIds: [CreateThankRecipientNestedInput!], $content: String!) {
    thankCreate(
      input: {
        user_id: $senderId
        content: $content
        thank_recipients: { create: $recipientIds }
        sent: false
      }
    ) {
      id
      from_who
      for_who
      content
      created_at
    }
  }
`

export const addThanksCustomRecipient = gql`
  mutation thank($recipient: String!, $content: String!, $senderId: ID!) {
    thankCreate(
      input: {
        for_who: $recipient
        content: $content
        sent: false
        user_id: $senderId
      }
    ) {
      id
      from_who
      for_who
      content
      created_at
    }
  }
`

export const getLoggedUser = gql`
  query loggedUser {
    me {
      id
      email
      name
      active
      created_at
      updated_at
    }
  }
`

export const userLogout = gql`
  mutation Logout {
    authUserLogout {
      success
    }
  }
`

export const getReactions = gql`
  query getReactions {
    emotions(first: 80, page: 1) {
      data {
        system_name
        id
      }
    }
  }
`

export const addReaction = gql`
  mutation addReaction($userId: ID!, $thankId: ID!, $emotionId: ID!) {
    reactionCreate(
      input: { user_id: $userId, thank_id: $thankId, emotion_id: $emotionId }
    ) {
      id
      thank {
        for_who
        from_who
        id
        content
        created_at
        reactions {
          emotion {
            system_name
          }
          user {
            name
          }
          id
        }
      }
    }
  }
`

export const deleteReaction = gql`
  mutation deleteReaction($reactionId: ID!) {
    reactionDelete(id: $reactionId) {
      id
      thank {
        for_who
        from_who
        id
        content
        created_at
        reactions {
          emotion {
            system_name
          }
          user {
            name
          }
          id
        }
      }
    }
  }
`

export const updateReaction = gql`
  mutation addReaction($userId: ID!, $id: ID!, $thankId: ID!, $emotionId: ID!) {
    reactionUpdate(
      input: {
        id: $id
        user_id: $userId
        thank_id: $thankId
        emotion_id: $emotionId
      }
    ) {
      id
      thank {
        for_who
        from_who
        id
        content
        created_at
        reactions {
          emotion {
            system_name
          }
          user {
            name
          }
          id
        }
      }
      __typename
    }
  }
`
