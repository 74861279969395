import { useCookies } from "react-cookie";

const useApplicationCookies = (cookie) => {
    const [cookies, setCookies, removeCookies] = useCookies([cookie]);

    return {
        cookies,
        setCookies,
        removeCookies,
    };
};

export default useApplicationCookies;
